/* // Based on Logo
// Primary color :=> #027bb0
// Secondary color:=> #c88E36 */
/* instangram color :=> #C231A1 */
.title-word {
  animation: color-animation 4s linear infinite;
}

.title-word-1 {
  --color-1: #c88e36;
  --color-2: #027bb0;
  --color-3: #e4a9a8;
}

.title-word-2 {
  --color-1: #ffa41b;
  --color-2: #c231a1;
  --color-3: #7152e9;
}

.title-word-3 {
  --color-1: #accfcb;
  --color-2: #e4a9a8;
  --color-3: #198754;
}

.title-word-4 {
  --color-1: #525fe1;
  --color-2: #f86f03;
  --color-3: #e4a9a8;
}

@keyframes color-animation {
  0% {
    color: var(--color-1);
  }
  32% {
    color: var(--color-1);
  }
  33% {
    color: var(--color-2);
  }
  65% {
    color: var(--color-2);
  }
  66% {
    color: var(--color-3);
  }
  99% {
    color: var(--color-3);
  }
  100% {
    color: var(--color-1);
  }
}
