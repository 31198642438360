/* // Based on Logo
// Primary color :=> #027bb0
// Secondary color:=> #c88E36 */
.waviy {
  position: relative;
}
.diifClr {
  color: #c88e36;
}
.primClr {
  color: #027bb0;
}
.waviy span {
  position: relative;
  display: inline-block;
  font-size: 20px;

  text-transform: uppercase;
  animation: flip 2s infinite;
  animation-delay: calc(0.2s * var(--i));
}
@keyframes flip {
  0%,
  80% {
    transform: rotateY(360deg);
  }
}
.w1 {
  --i: 1;
}
.w2 {
  --i: 2;
}
.w3 {
  --i: 3;
}
.w4 {
  --i: 4;
}
.w5 {
  --i: 5;
}
.w6 {
  --i: 6;
}
.w7 {
  --i: 7;
}
.w8 {
  --i: 8;
}
.w9 {
  --i: 9;
}
.w10 {
  --i: 10;
}
.w11 {
  --i: 11;
}
.w12 {
  --i: 12;
}
.w13 {
  --i: 13;
}
.w14 {
  --i: 14;
}
.w15 {
  --i: 15;
}

/* =============================================================== */
.bodyClasss {
  /* font-family: Montserrat; */
  text-align: center;
  color: #027bb0;
  font-size: 20px;
  /* padding-top: 40vh; */
  overflow: hidden;
}
.allDivClass {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
}
.divOne {
  animation: appear 6s infinite;
}
.divTwo {
  animation: reveal 6s infinite;
}
.divTwo span {
  font-size: 18px;
  color: #c88e36;
  animation: slide 6s infinite;
}
@keyframes appear {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes slide {
  0% {
    margin-left: -800px;
  }
  20% {
    margin-left: -800px;
  }
  35% {
    margin-left: 0px;
  }
  100% {
    margin-left: 0px;
  }
}
@keyframes reveal {
  0% {
    opacity: 0;
    width: 0px;
  }
  20% {
    opacity: 1;
    width: 0px;
  }
  30% {
    width: 430px;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    width: 430px;
  }
}

@media only screen and (max-width: 437px) {
  /* .bodyClasss {
    display: none;
  } */
  .bodyClasss {
    font-size: 16px;
  }
  .divTwo span {
    font-size: 14px;
  }
}

@media only screen and (max-width: 390px) {
  .bodyClasss {
    display: none;
  }
  /* .bodyClasss {
    font-size: 16px;
  }
  .divTwo span {
    font-size: 14px;
  } */
}
