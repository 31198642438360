/* // Based on Logo
// Primary color :=> #027bb0
// Secondary color:=> #c88E36 */

.animate-charcter {
  /* text-transform: uppercase; */
  background-image: linear-gradient(
    -225deg,
    #027bb0 0%,
    #44107a 29%,
    #7289da 67%,
    #c88e36 100%
      /* #231557 0%,
    #44107a 29%,
    #ff1361 67%,
    #fff800 100% */
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 5s linear infinite;
  display: inline-block;
  /* font-size: 190px; */
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}
